document.addEventListener('DOMContentLoaded', async () => {
  const { default: lottie } = await import('lottie-web');
  window.airplaneAnimation = lottie.loadAnimation({
    container: document.getElementsByClassName('th-error__plane')[0],
    path: '/error_pages/animation.json',
    renderer: 'svg',
    loop: true,
    autoplay: true
  });
})

window.Trailhead = window.Trailhead || {};

window.Trailhead.toggle404Animation = (element, pauseSrc, playSrc) => {
  const airplane = window.airplaneAnimation;
  const cloudClasses = '.cloud-01, .cloud-02, .cloud-03, .cloud-04';
  const clouds = document.querySelectorAll(cloudClasses);

  airplane.togglePause();
  if(airplane.isPaused){
    clouds.forEach((el) => { el.style.visibility = 'hidden' });
    element.getElementsByTagName('img')[0].src = playSrc;
  } else {
    clouds.forEach((el) => { el.style.visibility = 'visible' });
    element.getElementsByTagName('img')[0].src = pauseSrc;
  }
}

